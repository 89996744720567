<template>
  <div></div>
</template>

<script>
export default {
  name: "DotPad320Key-0.2",
  emits: ['twentyCellsLeftPanning', 'twentyCellsRightPanning', 'previousPage', 'nextPage', 'refresh', 'selectPenTool', 'selectEraserAllTool', 'addPage', 'deletePage'],
  methods: {
    onSingleConnection(dotPad, key) {
      switch (key.name) {
        // 20셀 왼쪽 패닝
        case "Left Pan":
          this.twentyCellsLeftPanning(dotPad);
          break;
        // 이전 페이지로 이동
        case "Function 1":
        case "Left Pan + Function 1":
          this.previousPage();
          break;
        // Pen Tool 선택
        case "Function 2":
          this.selectPenTool();
          break;
        // Erase All
        case "Function 3":
          this.selectEraserAllTool(dotPad);
          break;
        // 다음 페이지로 이동
        case "Function 4":
        case "Function 4 + Right Pan":
          this.nextPage();
          break;
        // 20셀 오른쪽 패닝
        case "Right Pan":
          this.twentyCellsRightPanning(dotPad);
          break;
        // 페이지 추가
        case "Function 1 + Function 2":
          this.addPage();
          break;
        // 페이지 삭제
        case "Function 3 + Function 4":
          this.deletePage();
          break;
        // 새로 고침
        case "Left Pan + Right Pan":
          if (key.pressedTime < 1500) {
            this.refresh(dotPad);
          }
          break;
        default:
          break;
      }
    },
    onMultipleConnection(dotPad, key) {
      switch (key.name) {
        // 20셀 왼쪽 패닝
        case "Left Pan":
          this.twentyCellsLeftPanning(dotPad);
          break;
        // 20셀 오른쪽 패닝
        case "Right Pan":
          this.twentyCellsRightPanning(dotPad);
          break;
        case "Left Pan + Right Pan":
          this.refresh(dotPad);
          break;
        default:
          break;
      }
    },
    twentyCellsLeftPanning(dotPad) {
      this.$emit('twentyCellsLeftPanning', dotPad);
    },
    twentyCellsRightPanning(dotPad) {
      this.$emit('twentyCellsRightPanning', dotPad);
    },
    previousPage() {
      this.$emit('previousPage');
    },
    nextPage() {
      this.$emit('nextPage');
    },
    refresh(dotPad) {
      this.$emit('refresh', dotPad);
    },
    selectPenTool() {
      this.$emit('selectPenTool');
    },
    selectEraserAllTool(dotPad) {
      this.$emit('selectEraserAllTool', dotPad);
    },
    addPage() {
      this.$emit('addPage');
    },
    deletePage() {
      this.$emit('deletePage');
    },
  }
}
</script>


<style scoped>

</style>
